

export default [
  {
    header: "LPC ",
  },
  {
    title: "الدورات",
    icon: "journal-bookmark",
    route: "add-course",
    resource: 'course',
    action:'view'
    // children: [
    //   {
    //     title: "Add Course",
    //     route: "index-course",
    //     icon: "PlusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "View Classical Courses",
    //     route: "classical-courses",
    //     icon: "BriefcaseIcon",
    //   },
    //   {
    //     title: "View Online Courses",
    //     route: "online-courses",
    //     icon: "YoutubeIcon",
    //   },
    // ],
  },
  {
    title: "الفئات",
    icon: "grid",
    route: "index-category",
    resource: 'category',
    action:'view'
    // children: [
    //   {
    //     title: " Category",
    //     route: "index-category",
    //     icon: "PlusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Add Category",
    //     route: "add-category",
    //     icon: "PlusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "View All Categories",
    //     route: "all-categories",
    //     icon: "ListIcon",
    //   },
    // ],
  },
  {
    title: "الدورات الاخرى",
    icon: "journal-bookmark-fill",
    route: "add-other-course",
    resource: 'other_course',
    action:'view'
    // children: [
    //   {
    //     title: "Add Dedicated  Course",
    //     route: "index-other-course",
    //     icon: "PlusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "View Dedicated Course",
    //     route: "deticated-courses",
    //     icon: "ShoppingBagIcon",
    //   },
    //   {
    //     title: "Schedule Dedicated Course",
    //     route: "schedual-deticatedd-courses",
    //     icon: "ShoppingBagIcon",
    //   },
    //   {
    //     title: " Deticated Courses Countent",
    //     route: "Deticated-courses-content",
    //     icon: "ShoppingBagIcon",
    //   },
    //   {
    //     title: "Seo Detcated Course",
    //     route: "seo-detcated-course",
    //     icon: "ShoppingBagIcon",
    //   },
    // ],
  },
  {
    title: "المدرسين",
    icon: "person-circle",
    route:'add-instructor',
    resource: 'instructor',
    action:'view'
    // children: [
    //   {
    //     title: "Add Instructor",
    //     route: "index-instructor",
    //     icon: "PlusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "View All Instructor",
    //     route: "instructor-list",
    //     icon: "ListIcon",
    //   },
    // ],
  },
  {
    title: "المدن و المواقع",
    icon: "map",
    route: "add-city",
    resource: 'city',
    action:'view'
    // children: [
    //   {
    //     title: "Add City",
    //     route: "index-city",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "View Cities",
    //     route: "cities-list",
    //     icon: "plusIcon",
    //   },
    //   {
    //     title: "Manage Location",
    //     route: "manage-location",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "View Locations",
    //     route: "location-lisy",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "الاسعار",
    icon: "currency-pound",
    route: "classical-course-price",
    resource: 'price',
    action:'view'
    // children: [
    //   {
    //     title: "Classical Course Price",
    //     route: "index-price",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Online Course Price",
    //     route: "online-course-price",
    //     icon: "plusIcon",
    //   },
    //   {
    //     title: "View Prices",
    //     route: "all-prices",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "الجدولة",
    icon: "calendar2-date",
    route: "schedule-classical-course",
    resource: 'schedule',
    action:'view'
    // children: [
    //   {
    //     title: "Schedule Classical Course ",
    //     route: "schedule-index",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Schedule Online Course ",
    //     route: "schedule-online-course",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "View Schedule",
    //     route: "all-schedule",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "المحتوى",
    icon: "file-diff",
    route: "course-content",
    resource: 'course_content',
    action:'view'
    // children: [
    //   {
    //     title: "Course Content",
    //     route: "index-content",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Category Content",
    //     route: "category-content",
    //     icon: "plusIcon",
    //   },
    //   {
    //     title: "Category Page Content",
    //     route: "category-page-content",
    //     icon: "plusIcon",
    //   },
    //   {
    //     title: "City Content",
    //     route: "city-content",
    //     icon: "plusIcon",
    //   },
    //   {
    //     title: "Category in City Content",
    //     route: "category-in-city-content",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "الصفحة الرئيسية",
    icon: "shop",
    route: "add-upcoming-course",
    resource: 'upcoming-course',
    action:'view'
    // children: [
    //   {
    //     title: "Upcoming Courses ",
    //     route: "index-homepage",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Create Upcoming Courses",
    //     route: "add-upcoming-course",
    //     icon: "plusIcon",
    //   },
    //   {
    //     title: "Feature Course",
    //     route: "feature-course",
    //     icon: "plusIcon",
    //   },
    //   {
    //     title: "Change Feature Course",
    //     route: "change-feature-course",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "محركات البحث",
    icon: "search",
    route:'seo-classical-course',
    resource: 'seo',
    action:'read'
    // children: [
    //   {
    //     title: "Seo Course",

    //     icon: "plusIcon",
    //     children: [
    //       {
    //         title: "Seo Classical Course",
    //         route: "seo-classical-course",
    //         icon: "plusIcon",
    //         // resource: 'all',
    //         // action: 'read',
    //       },
    //       {
    //         title: "Seo Online Course",
    //         route: "seo-online-course",
    //         icon: "plusIcon",
    //       },
    //       {
    //         title: "Seo  Course In City",
    //         route: "seo-course-in-city",
    //         icon: "plusIcon",
    //       },
    //     ],
    //     // resource: 'all',
    //     // action: 'read',
    //   },

    //   {
    //     title: "Seo Category",

    //     icon: "plusIcon",
    //     children: [
    //       {
    //         title: "Seo Classical Category",
    //         route: "seo-classical-category",
    //         icon: "plusIcon",
    //         // resource: 'all',
    //         // action: 'read',
    //       },
    //       {
    //         title: "Seo Online Category",
    //         route: "seo-online-category",
    //         icon: "plusIcon",
    //       },
    //     ],
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Seo Category In  City",
    //     route: "seo-category-in-city",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Seo Pages ",
    //     route: "seo-pages",
    //     icon: "plusIcon",
    //   },
    //   // {
    //   //   title: "Seo Home Pgages ",
    //   //   route: "seo-home-page",
    //   //   icon: "plusIcon",
    //   // },
    //   {
    //     title: "Seo  City",
    //     route: "seo-city",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   // {
    //   //   title: "Seo Cities Page ",
    //   //   route: "seo-cities-page",
    //   //   icon: "plusIcon",
    //   // },
    //   // {
    //   //   title: "Seo Become An Instructor",
    //   //   route: "seo-become-instructor",
    //   //   icon: "plusIcon",
    //   // },
    //   // {
    //   //   title: "Seo Category  Pages ",
    //   //   route: "seo-categories-page",
    //   //   icon: "plusIcon",
    //   // },

    //   // {
    //   //   title: "Seo In House Training",
    //   //   route: "in_house_training",
    //   //   icon: "plusIcon",
    //   // },
    // ],
  },
 
  {
    title: "الشهادات",
    icon: "award",
    route: "add-certificates",
    resource: 'certificate',
    action:'view'
    // children: [
    //   {
    //     title: "Add Certificate ",
      
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "All Certificates",
    //     route: "all-certificates",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "السلايدر",
    icon: "chevron-double-right",
    route: "index-slider",
    resource: 'slider',
    action:'view'
    // children: [
    //   {
    //     title: "Add Slider ",
    //     route: "index-slider",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "All Sliders",
    //     route: "all-sliders",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "إضافة مقال / خبر",
    icon: "file-post",
    route: "add-article",
    resource: 'post',
    action:'view'
    // children: [
    //   {
    //     title: "Add Article ",
    //     route: "index-article",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "All Articles",
    //     route: "all-articales",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "اتصل بنا",
    icon: "person-lines-fill",
    route: "contact-us",
    resource: 'contact-us',
    action:'view'},
  {
    title: "العملاء",
    icon: "person-lines-fill",
    route: "add-client",
    resource: 'client',
    action:'view'
    // children: [
    //   {
    //     title: "Add Client ",
    //     route: "index-client",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "All Clients",
    //     route: "all-client",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "الاعتمادات",
    icon: "journal-bookmark",
    route: "index-accreditation",
    resource: 'accreditation',
    action:'view'
    // children: [
    //   {
    //     title: "Add Accreditation ",
    //     route: "index-accreditation",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "All Accreditations",
    //     route: "all-accreditations",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "المعرض",
    icon: "images",
    route: 'gallery-video',
    resource: 'image',
    action:'view'
    // children: [
    //   {
    //     title: "Add Images ",
    //     route: "gallery-index",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    
    // ],
  },
  {
    title: "الايميل",
    icon: "mailbox",
    route: "index-mails",
    resource: 'mail',
    action:'view'
    // children: [
    //   {
    //     title: "View Mails",
    //     route: "index-mails",
    //     icon: "plusIcon",
    //   },
    // ],
  },
  {
    title: "التوظيف",
    icon: "person-lines-fill",
    route: "index-career",
    resource: 'career',
    action:'view'},
  {
    title: "الاعدادات",
    icon: "sliders",
    route: "add-user",
    resource: 'setting',
    action:'read'
    // children: [
    //   {
    //     title: "User Profile",
    //     route: "setting-index",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Users Management",
        
    //     icon: "plusIcon",
    //     children: [
    //       {
    //         title: "Add User",
    //         route: "add-user",
    //         icon: "plusIcon",
    //         // resource: 'all',
    //         // action: 'read',
    //       },
    //       {
    //         title: "All User",
    //         route: "all-user",
    //         icon: "plusIcon",
            
    //       },
    //     ],
        
    //   },
    //   {
    //     title: "Links Redirection",
    //     route: "links-redirection",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Links Redirection Arabic",
    //     route: "links-redirection-arabic",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Courses Durations",
    //     route: "courses-durations",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Analytic Codes",
    //     route: "analytic-codes",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "ZOHO Integration",
    //     route: "zoho-integration",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Home Page Sections",
    //     route: "home-page-section",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Copying Protection",
    //     route: "copying-protection",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Optimize Website",
    //     route: "optimize-website",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
    //   {
    //     title: "Code Generator",
    //     route: "code-generator",
    //     icon: "plusIcon",
    //     // resource: 'all',
    //     // action: 'read',
    //   },
      
    // ],
  },
 

  // {
  //   title: "ConsultingServices",
  //   route: "ConsultingServices",
  //   resource: 'consulting_sevices',
  //   action:'view',
  //   icon: "info",
  // },
  {
    title: "صفحات الموقع ",
    route: "ConsultingServices",
    resource: 'consulting_sevices',
    action:'view',
    icon: "info",
  },
  {
    title: 'Web Pages',
    route: 'ConsultingServices',
    resource: 'consulting_sevices',
    action:'view',
    icon: "info",
  },
  
];
